import { createSlice } from "@reduxjs/toolkit"

export const preventiveSlice = createSlice({
  name: 'preventiveMaintenance',
  initialState: {
    data: {}
  },
  reducers: {
    updateInitialPreventiveStateData: (state, action) => {
      let newState = {...state.data};
      
      const initialData = action.payload?.data || [];
      const page = action.payload?.page || 1;
      
      if (initialData?.length > 0) {
        if(!newState[page]) {
          newState[page] = {};
        }
        //
        initialData?.map(({preventiveMaintenancesColumns , id}) => {
          if(preventiveMaintenancesColumns?.[0]) {
            const { 
              status = undefined, 
              reason = undefined, 
              spareParts = [], 
              columns = {},
            } = preventiveMaintenancesColumns?.[0];

            newState[page][id] = {
              columns,
              status,
              reason,
              spareParts,
              fire_system_id: id,
            }
          }
        })
      }
      //update state
      state.data = newState;
    },
    updatePreventiveValues: (state, action) => {
      let newState = {...state.data};
      const { id, values, page } = action.payload;
      //
      if(!newState[page]) {
        newState[page] = {}
      }
      //
      if (!newState[page][id]) {
        newState[page][id] = {};
      }
      //
      values.forEach(({key, value}) => {
        newState[page][id][key] = value;
      });
      //
      state.data = newState;
    },
    updateColumnStatus: (state, action) => {
      const { id, column, value, page } = action.payload;
      let newState = { ...state.data };

      if(!newState[page]) {
        newState[page] = {}
      }

      if (!newState[page][id]) {
        newState[page][id] = { columns: {} };
      }

      if (!newState[page][id].columns) {
        newState[page][id].columns = {};
      }

      if (value != undefined) {
        newState[page][id].fire_system_id = id; 
        newState[page][id].columns[column] = value;
      } else {
        delete newState[page][id].columns[column];
      }

      state.data = newState;
    },
    resetPreventiveValues: (state) => {
      state.data = {};
    },
  },
});

export const { 
  updateInitialPreventiveStateData, 
  updatePreventiveValues, 
  updateColumnStatus, 
  resetPreventiveValues,
} = preventiveSlice.actions

export default preventiveSlice.reducer;