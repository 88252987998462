// ** React Imports
import { useCallback, useEffect, useRef, useState } from 'react';
import { Label, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import './custom.scss';

const MonthsPicker = ({
  name,
  id,
  label,
  autoClose = false,
  className = '',
  handleDateChange = () => false,
  autoApply = true,
  hasDefaultDate = false,
  ...props
}) => {
  let datepickerRef = useRef(null);
  const [picker, setPicker] = useState(hasDefaultDate ? new Date() : undefined);

  const applyDatepicker = useCallback(() => {
    let dateInstance = moment(picker);
    let startMonth = dateInstance.startOf('month').format('YYYY-MM-DD');
    let endMonth = dateInstance.endOf('month').format('YYYY-MM-DD');
    //
    handleDateChange([startMonth, endMonth]);
    //
    if (datepickerRef.current) {
      datepickerRef.current.setOpen(false);
    }
  }, [picker, handleDateChange, datepickerRef]);

  const closeDatepicker = useCallback(() => {
    setPicker(new Date());
    datepickerRef.setOpen(false);
  }, []);

  const onChange = useCallback((date) => {
    setPicker(date);
  }, []);

  useEffect(() => {
    if (autoApply) {
      applyDatepicker();
    }
  }, [autoApply, picker]);


  return (
    <div className="cursor-pointer">
      {label && (
        <Label className="form-label" htmlFor={id}>
          {label}
        </Label>
      )}
      <DatePicker
        {...props}
        className={`form-control cursor-pointer ${className}`}
        selected={picker}
        id={id}
        name={name}
        onChange={(date) => onChange(date)}
        dateFormat="MMMM - yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        shouldCloseOnSelect={autoClose}
        ref={datepickerRef}
        >
        {!autoApply && (
          <>
            <div className="clearfix"></div>
            <div className="custom-flex-row justify-content-between align-items-center w-100">
              <Button.Ripple
                color="relief-primary"
                className="flex-2-col rounded-0 apply-dp"
                onClick={() => {
                  closeDatepicker();
                }}>
                Cancel
              </Button.Ripple>
              <Button.Ripple
                color="relief-primary"
                className="flex-2-col rounded-0 close-dp"
                onClick={() => applyDatepicker()}>
                Apply
              </Button.Ripple>
            </div>
          </>
        )}
      </DatePicker>
    </div>
  );
};

export default MonthsPicker;
